<template>
  <div class="mt-16 p-12 flex items-center bg-gray-100 sombra-2 flex-col">
    <!--<div class="p-12 white sombra-1">
       <h1 class="font-bold text-3xl sm:text-3xl mb-1">Horarios de atencion</h1>
      <p>
        Los horarios son flexibles, para organizar reuniones fuera de horario
        llamar por telefono.
      </p>
      <table class="table-auto">
        <thead>
          <tr>
            <th class="px-4 py-2"></th>
            <th class="px-4 py-2">Mañana</th>
            <th class="px-4 py-2">Tarde</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="border px-4 py-2">Lunes a Viernes</td>
            <td class="border px-4 py-2">9:00 - 12:00</td>
            <td class="border px-4 py-2">16:00 - 19:00</td>
          </tr>
          <tr class="bg-gray-100">
            <td class="border px-4 py-2">Sabados</td>
            <td class="border px-4 py-2">9:00 - 12:00</td>
            <td class="border px-4 py-2"></td>
          </tr>
        </tbody>
      </table>
    </div>-->

    <h1 class="font-bold text-3xl sm:text-3xl mb-1 block">Contacto</h1>

    <div class="flex-row">
      <div class="flex flex-col">
        <div
          class="flex items-center hover:text-red-600 transition duration-500"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-phone stroke-current text-red-600"
          >
            <path
              d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"
            />
          </svg>
          <p class="m-4" @click="doCopy('3404 420045', 'Telefono')">
            <a href="tel:+3404420045">
              <b class="font-semibold">Telefono:</b> 3404 420045
            </a>
          </p>
        </div>
        <div
          class="flex items-center hover:text-blue-600 transition duration-500"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-mail stroke-current text-blue-600"
          >
            <path
              d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"
            />
            <polyline points="22,6 12,13 2,6" />
          </svg>

          <p class="m-4" @click="doCopy('suvibasa@gmail.com', 'E-mail')">
            <a href="mailto:suvibasa@gmail.com">
              <b class="font-semibold">E-Mail:</b> suvibasa@gmail.com
            </a>
          </p>
        </div>
        <div
          class="flex items-center hover:text-gray-600 transition duration-500"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-map-pin stroke-current text-gray-600"
          >
            <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" />
            <circle cx="12" cy="10" r="3" />
          </svg>
          <p
            class="m-4"
            @click="doCopy('San Martin 009 - San Carlos Sud', 'Direccion')"
          >
            <b class="font-semibold">Direccion:</b> San Martin 009 - San Carlos
            Sud
          </p>
        </div>
      </div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d599.7690739643501!2d-61.09841782957168!3d-31.74848948811347!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95b59d03e71f55e1%3A0x289aad8b720a5ff0!2sSuviba%20SA%20Sistemas%20Constructivos%20Industrializados!5e0!3m2!1ses!2sar!4v1598993879612!5m2!1ses!2sar"
        height="300"
        frameborder="0"
        style="border:0;"
        allowfullscreen
        aria-hidden="false"
        tabindex="0"
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    doCopy(text, type) {
      this.$copyText(text)
        .then(this.onCopy(type))
        .catch(this.onError());
    },
    onCopy: function(type) {
      this.$toasted.show(type + " copiado!", {
        theme: "outline",
        position: "top-center",
        duration: 1000
      });
    },
    onError: function() {
      //console.log(e);
    }
  }
};
</script>

<style></style>
